// ** React imports 
import React from 'react'

// ** MUI Imports
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useMediaQuery } from '@mui/material'

// ** Components
import UserDropdown from 'core/layouts/components/shared-components/UserDropdown'
import NotificationDropdown from 'core/layouts/components/shared-components/NotificationDropdown'
import ApplicationsDropdown from 'core/layouts/components/shared-components/ApplicationsDropdown'

// ** Icons Imports
import TooltipIcon from 'mdi-material-ui/HelpCircleOutline'

// ** Auth Hook
import { useAuth } from 'hooks/useAuth'


const AppBarContent = props => {

  // ** Props
  const { hidden, settings, saveSettings } = props

  //** Hooks
  const auth = useAuth()
  const medium = useMediaQuery(theme => theme.breakpoints.down('md'))

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: "space-between",
          alignItems: 'center',
          ...(!medium ? { mx: "2rem" } : { mx: 1 })
        }}>
        {!medium && <Box
          sx={{
            border: theme => `1px solid ${theme.palette.grey[300]}`,
            ...(!medium && { mx: "1rem" }),
            px: "1rem",
            py: "0.2rem",
            borderRadius: "1em"
          }}>
          <Typography sx={{ color: 'text.secondary', fontSize: "0.8125rem", fontWeight: 600 }}>{auth.user?.email}</Typography>
        </Box>}
        <TooltipIcon
          sx={{
            color: 'text.secondary',
            ...(!medium ? { mx: "1rem" } : { mx: 1 })
          }}
        />
        <NotificationDropdown settings={settings} />
        {auth?.user?.access_apps?.filter(app => app.access === true)?.length > 0 && <ApplicationsDropdown settings={settings} />}
      </Box>
      <UserDropdown settings={settings} />
    </Box >
  )
}

export default AppBarContent
