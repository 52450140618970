// ** React Import
import React from 'react'
import { Link } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { styled, useTheme } from '@mui/material/styles'
import themeConfig from 'configs/themeConfig'
import { useMediaQuery } from '@mui/material'

// ** Theme Config Import
import logo from 'assets/images/logo.png'

// Styled Img component
const Img = styled('img')(({ theme }) => ({
  height: 60,
  width: 220,
  [theme.breakpoints.down('md')]: {
    width: 32,
    height: 32
  }
}))

const StyledLink = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  marginRight: theme.spacing(8)
}))

const AppBarContent = props => {
  // ** Props
  const {
    horizontalAppBarContent: userHorizontalAppBarContent,
    horizontalAppBarBranding: userHorizontalAppBarBranding,
    toggleNavVisibility
  } = props

  // ** Hooks
  const theme = useTheme()
  const medium = useMediaQuery(theme => theme.breakpoints.down('md'))

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', justifyContent: 'start' }}>
        <IconButton
          size='large'
          edge='start'
          aria-label='menu'
          sx={{ color: 'common.black', mr: '0.5rem' }}
          onClick={toggleNavVisibility}
        >
          <MenuIcon />
        </IconButton>
        <Stack direction='row' alignItems='center' gap={1}>
          {/* {medium && <Img alt="google_cloud" src="/logo/main-logo.png" />}
          {!medium && <Img alt="google_cloud" src="/logo/premier-cloud.svg" />} */}
          <Typography variant='h6' sx={{ fontWeight: 600, color: 'text.secondary', whiteSpace: 'nowrap' }}>
            <img alt='logo' src={logo} height={30} style={{ marginTop: 10 }} />
          </Typography>
        </Stack>
      </Box>
      {userHorizontalAppBarContent ? userHorizontalAppBarContent(props) : null}
    </Box>
  )
}

export default AppBarContent
