// ** React Imports
import React, { Fragment, useState } from 'react'

// ** API CALLS
import { createCustomer, sendInvitation } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'
import { useAuth } from 'hooks/useAuth'

// ** Styled Box
const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  boxShadow: theme.shadows[5],
  paddingTop: '0.125rem',
  paddingBottom: '0.125rem',
  paddingRight: '0.725rem',
  paddingLeft: '0.725rem',
  borderRadius: '1rem',
  cursor: 'pointer',
  width: 'fit-content'
}))

const defaultValues = {
  domain: '',
  admin: '',
  showAutoTopUp: false
}

const AddCustomerDialog = props => {
  // ** Props
  const { setQuery, setMessage, refetch } = props

  // ** Hooks
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues })
  const { user } = useAuth()
  // ** State
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  // ** Functions
  const handleClickOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const onSubmit = async customer => {
    setLoading(true)
    try {
      const email = {
        from: 'orders@premiercloud.com', // Sender address
        to: customer?.admin, // Receiver
        subject: `Premier Cloud Licensing Portal | ${customer?.domain}` // Subject line
      }
      const response = await createCustomer(customer)
      const emailRes = await sendInvitation({ displayName: user?.name, content: email })
      setQuery('list')
      setMessage({ success: true, error: false, value: 'Customer was added successfully' })
      refetch()
      setLoading(false)
      setOpen(false)
    } catch (error) {
      if (error.response.status === 401) setMessage({ success: true, error: false, value: 'This user already exists.' })
      else setMessage({ success: true, error: false, value: 'There was an error while adding the customer' })
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <StyledBox onClick={handleClickOpen}>
        <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 36 36'>
          <path fill='#34A853' d='M16 16v14h4V20z'></path>
          <path fill='#4285F4' d='M30 16H20l-4 4h14z'></path>
          <path fill='#FBBC05' d='M6 16v4h10l4-4z'></path>
          <path fill='#EA4335' d='M20 16V6h-4v14z'></path>
          <path fill='none' d='M0 0h36v36H0z'></path>
        </svg>
        <Typography sx={{ color: 'text.secondary', fontSize: '0.825rem', fontWeight: 600 }}>New Customer</Typography>
      </StyledBox>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle id='alert-dialog-title'>Add Customer</DialogTitle>
        <DialogContent>
          <form id='add-customer-form' onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name='domain'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        variant='standard'
                        value={value ?? ''}
                        label='Domain'
                        onChange={onChange}
                        error={Boolean(errors.domain)}
                        placeholder='domain xyz'
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name='admin'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        type='email'
                        variant='standard'
                        value={value ?? ''}
                        label='Super admin email'
                        onChange={onChange}
                        error={Boolean(errors?.admin)}
                        placeholder='jhondoe@gmail.com'
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <Controller
                    name='showAutoTopUp'
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        label='Auto Top up'
                        control={<Checkbox checked={value} onChange={onChange} error={Boolean(errors.showAutoTopUp)} />}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className='dialog-actions-dense' sx={{ mt: '1rem' }}>
          <Button disabled={loading} onClick={handleClose} size='small' variant='text' color='inherit'>
            Cancel
          </Button>
          <Button
            disabled={loading}
            endIcon={loading ? <CircularProgress sx={{ color: 'common.white', mt: 0 }} size='0.825rem' /> : null}
            type='submit'
            form='add-customer-form'
            variant='contained'
            size='small'
            sx={{ fontWeight: 600, primary: 'common.blue' }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default AddCustomerDialog
