// ** React imports
import React, { useState } from 'react'


// ** MUI Imports
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import AppBar from '@mui/material/AppBar'
import { styled } from '@mui/material/styles'
import MuiToolbar from '@mui/material/Toolbar'
import { useMediaQuery } from '@mui/material'

// ** Icons Imports
import ArrowUp from 'mdi-material-ui/ArrowUp'

// import Navigation from './components/horizontal/navigation'
import ScrollToTop from '../components/scroll-to-top'
import AppBarContent from './components/horizontal/app-bar-content'
import Navigation from './components/vertical/navigation'
import themeConfig from 'configs/themeConfig'

const { navigationSize, collapsedNavigationSize } = themeConfig


// ** Styled Component

const HorizontalLayoutWrapper = styled('div')({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  overflow: 'clip'
})

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  width: '100%',
  // zIndex: theme.zIndex.drawer + 1,
  padding: `${theme.spacing(0, 6)} !important`,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  }
}))

const ContentWrapper = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(4),
  backgroundColor: theme.palette.common.white,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: theme.spacing(2),
    transition: 'padding .25s ease-in-out',
  },
  [theme.breakpoints.not('xs')]: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 70,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${navigationSize}px`,
    }),
  }
}))

const VerticalLayout = props => {



  // ** Props
  const {
    hidden,
    children,
    settings,
    scrollToTop,
    saveSettings,
  } = props

  // ** Vars
  const { skin, appBar, navHidden, appBarBlur, contentWidth } = settings
  const navWidth = navigationSize
  const navigationBorderWidth = 0
  const collapsedNavWidth = collapsedNavigationSize

  // ** Check screen size for default open side bar
  const small = useMediaQuery(theme => theme.breakpoints.up('sm'))

  // ** States
  const [navVisible, setNavVisible] = useState(small)

  // ** Toggle Functions
  const toggleNavVisibility = () => {
    saveSettings({ ...settings, drawer: !navVisible })
    setNavVisible(!navVisible)
  }



  return (
    <HorizontalLayoutWrapper className='layout-wrapper'>
      <AppBar
        color='default'
        elevation={0}
        className='layout-navbar-and-nav-container'
        position="sticky"
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          borderBottom: theme => `1px solid ${theme.palette.grey[300]}`,
          //backgroundColor: theme => theme.palette.background.paper,
          transition: 'border-bottom 0.2s ease-in-out, backdrop-filter .25s ease-in-out, box-shadow .25s ease-in-out',
        }}
      >
        <Navigation
          navWidth={navWidth}
          navVisible={navVisible}
          setNavVisible={setNavVisible}
          collapsedNavWidth={collapsedNavWidth}
          toggleNavVisibility={toggleNavVisibility}
          navigationBorderWidth={navigationBorderWidth}
          {...props}
        />
        <Box
          className='layout-navbar'
          sx={{
            width: '100%',
            ...(navHidden ? {} : { borderBottom: theme => `1px solid ${theme.palette.divider}` }),
          }}
        >
          <Toolbar
            className='navbar-content-container'
            sx={{
              mx: 'auto',
              minHeight: theme => `${theme.mixins.toolbar.minHeight - 1}px !important`,
              backgroundColor: "#FFF"
            }}
          >
            <AppBarContent toggleNavVisibility={toggleNavVisibility} {...props} hidden={hidden} settings={settings} saveSettings={saveSettings} />
          </Toolbar>
        </Box>
      </AppBar>

      <ContentWrapper className='layout-page-content' open={navVisible}>
        {children}
      </ContentWrapper>

      {/* <Footer {...props} /> */}


      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className='mui-fixed'>
          <Fab color='primary' size='small' aria-label='scroll back to top'>
            <ArrowUp />
          </Fab>
        </ScrollToTop>
      )}
    </HorizontalLayoutWrapper>
  )
}

export default VerticalLayout
