// ** React Imports
import React, { useState, Fragment } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import MuiMenu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

// ** Icons Imports
import DotsGrid from 'mdi-material-ui/DotsGrid'

// ** Images imports
import MainLogo from "assets/images/main_logo.svg"
import AdminPlus from "assets/images/google_podcast.svg"
// import LicencingApp from "images/google_pay.svg"

// ** Third Party Components
// import PerfectScrollbarComponent from 'react-perfect-scrollbar'

// ** Custom Components Imports
// import CustomAvatar from '../../../components/mui/avatar'

// ** Context
import { useAuth } from 'hooks/useAuth'
import { useCookies } from 'react-cookie'

// ** Styled Menu component
const Menu = styled(MuiMenu)(({ theme }) => ({
    '& .MuiMenu-paper': {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        paddingRight: "0.5rem",
        paddingLeft: "0.5rem",
        width: 380,
        overflow: 'hidden',
        borderRadius: "0.5em",
        marginTop: theme.spacing(2.5),
        marginLeft: theme.spacing(15),
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    '& .MuiMenu-list': {
        //padding: 0
    }
}))





// ** Styled component for Images
const Img = styled("img")(({ theme }) => ({
    height: 40,
    width: 40
}))

const ApplicationsDropdown = props => {


    // ** Props
    const { settings } = props

    // ** States
    const [anchorEl, setAnchorEl] = useState(null)

    // ** Hooks
    const { user } = useAuth()
    const hidden = useMediaQuery(theme => theme.breakpoints.down('lg'))
    const [cookies, setCookie, removeCookie] = useCookies(['sharedToken'])

    // ** Vars
    const { direction } = settings

    const handleDropdownOpen = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleDropdownClose = () => {
        setAnchorEl(null)
    }

    const redirectHandler = (appName) => {

        if (appName === "Licencing App")
            window.open(`app.premiercloud.com/?token=${cookies.sharedToken}`, "_blank")
        else if (appName === "Admin plus")
            window.open(`https://adminplus-accelerant.oa.r.appspot.com/?token=${cookies.sharedToken}`, "_blank")
    }



    return (
        <Fragment>
            <IconButton aria-haspopup='true' onClick={handleDropdownOpen} aria-controls='customized-menu' sx={{ color: 'common.white' }}>
                <DotsGrid sx={{ color: 'text.secondary' }} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleDropdownClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
            >
                <Grid container sx={{ my: 0, mx: 0 }}>
                    {/* <ScrollWrapper>  .filter(app => app.access === true)? */}
                    {user?.access_apps?.filter(app => app.access === true)?.map((el, index) => (
                        <Grid
                            key={index}
                            item
                            xs={3}
                            sx={{
                                display: ' flex',
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: 'center',
                                cursor: 'pointer',
                                borderRadius: "8px",
                                px: "5px",
                                py: "5px",
                                mx: "0.925rem",
                                "&:hover": { backgroundColor: theme => theme.palette.grey[100] }
                            }}
                            onClick={() => redirectHandler(el.name)}
                        >
                            {el?.name === "Billing Automation" && <Img alt="billing_automation" src={MainLogo} />}
                            {el?.name === "Admin plus" && <Img alt={el?.name} src={AdminPlus} />}
                            {/* {el?.name === "Licencing App" && <Img alt={el?.name} src={LicencingApp} />} */}
                            <Typography sx={{ whiteSpace: "nowrap", fontSize: "0.725rem", fontWeight: 600, color: "text.secondary" }}>
                                {el?.name === "Billing Automation" ? "Billing Auto" : el?.name}
                            </Typography>
                        </Grid>))}
                    {/* </ScrollWrapper> */}
                </Grid>

            </Menu>
        </Fragment>
    )
}

export default ApplicationsDropdown
