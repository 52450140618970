// ** React Imports
import React, { Fragment, useEffect, useState } from 'react'

// ** API CALLS
import { getCustomersList, createLicenceAccess, getUsers } from 'configs/axios/api_helper'

// ** Hooks
import { useAuth } from 'hooks/useAuth'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import IconButton from '@mui/material/IconButton'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'

// ** Icon Imports
import AddIcon from 'mdi-material-ui/Plus'
import Alert from '../alert'

const AddAdmin = props => {
  // **Props
  const { domain, admin, refetch } = props

  // ** Hooks
  const auth = useAuth()

  // ** State
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ success: false, error: false, value: '' })
  const [selectedUser, setSelectedUser] = useState(null)
  const [options, setOptions] = useState([])
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [customerId, setCustomerId] = useState(null)
  const [inputError, setInputError] = useState(false)

  // ** Functions
  const getCustomerId = async () => {
    try {
      setLoadingSearch(true)
      const response = await getUsers({ domain: domain })
      setCustomerId(response?.data?.result?.[0]?.customerId)
      setLoadingSearch(false)
    } catch (error) {
      setLoadingSearch(false)
      console.log('error', error)
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
    getCustomerId()
  }
  const handleClose = () => setOpen(false)

  const handleCloseMessage = () => {
    setMessage({ success: false, error: false, value: '' })
  }

  const filterHandler = async (event, email) => {
    const { value } = event.target
    setSelectedUser(value)
    if (value) {
      try {
        setLoadingSearch(true)
        setOptions([])
        const {
          data: { result }
        } = await getCustomersList(value, customerId)
        setOptions(Array.isArray(result) ? result : [])
        setLoadingSearch(false)
      } catch (error) {
        setLoadingSearch(false)
        console.log('error: ', error)
      }
    } else {
      setOptions([])
    }
  }

  const addUserHandler = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Check if the selected user's email matches the email format
    if (typeof selectedUser !== 'object' && !emailRegex.test(selectedUser)) {
      setInputError(true) // Set error state to true if not in email format
      return // Stop further execution of addUserHandler
    } else {
      setInputError(false) // Clear error state if in email format
    }

    setMessage({ ...message, loading: true })
    const data = {
      adminDetails: { id: auth.user?.id, name: auth.user?.name ?? '' },
      adminEmail: auth.user?.email,
      customerId: typeof selectedUser !== 'object' ? customerId : selectedUser?.customerId,
      userEmail: typeof selectedUser !== 'object' ? selectedUser : selectedUser?.primaryEmail,
      userName:
        typeof selectedUser !== 'object'
          ? selectedUser?.split('@')?.[0]
          : selectedUser?.name?.fullName ?? selectedUser?.name
    }

    try {
      const response = await createLicenceAccess(data)
      refetch()
      setMessage({ error: true, success: false, loading: false, value: 'Admin was added successfully.' })
      setOpen(false)
    } catch (error) {
      if (error.response.status === 401)
        setMessage({
          error: true,
          success: false,
          loading: false,
          value: 'This admin already exists.'
        })
      else
        setMessage({
          error: true,
          success: false,
          loading: false,
          value: 'There was an error occured. Please try again.'
        })
    }
  }

  return (
    <Fragment>
      <IconButton size='medium' edge='start' sx={{ color: 'text.secondary' }} onClick={handleClickOpen}>
        <AddIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle id='alert-dialog-title'>Add admin</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Autocomplete
              id='autocomplete-users'
              autoHighlight
              freeSolo
              onInputChange={filterHandler}
              loading={loadingSearch}
              options={options.map(option => option)}
              getOptionLabel={option => option?.primaryEmail}
              onChange={(event, value) => setSelectedUser(value)}
              sx={{ width: '100%' }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant='standard'
                  placeholder="Search for user's email"
                  error={inputError} // Apply error state to the input
                  helperText={inputError ? 'Please enter a valid email address' : ''} // Display error message
                />
              )}
            />
            {/* <Autocomplete
              id='autocomplete-domains'
              popupIcon={''}
              loading={loading}
              getOptionLabel={option => option?.primaryEmail}
              renderInput={params => <TextField {...params} variant='standard' placeholder="Search for user's email" />}
              options={options}
              onChange={(event, value) => setSelectedUser(value)}
              sx={{ width: '100%' }}
            /> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions className='dialog-actions-dense' sx={{ mt: '1rem' }}>
          <Button disabled={loading} onClick={handleClose} size='small' variant='text' color='inherit'>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={addUserHandler}
            endIcon={loading ? <CircularProgress sx={{ color: 'common.red', mt: 0 }} size='0.825rem' /> : null}
            variant='text'
            size='small'
            sx={{ fontWeight: 600 }}
            color='primary'
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Alert message={message} handleCloseMessage={handleCloseMessage} />
    </Fragment>
  )
}

export default AddAdmin
