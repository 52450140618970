import { del, get, post, put, patch, uploadFile } from './api_setup'
import * as url from './url_helper'

// ** Authentification
const getUserAuthorization = (email, configs = {}) => get(url.GET_USER_AUTHORIZATION + `?email=${email}`, configs)
const getUserAppsAccess = data => post(url.GET_USER_APPS_ACCESS, data)
const getClientUserAuthorization = (email, configs = {}) => get(`?email=${email}`, configs)

// ** Subscriptions
const getOrderHistory = ({ query }) => get(url.GET_ORDERS + query)

// ** Domains
const getDomainByQuery = ({ query = '', limit = '', nextToken = '' }) =>
  get(url.GET_SEARCH_DOMAINS + query + '&limit=' + limit + '&nextToken=' + nextToken)

// ** Customers
const getCustomers = ({ query }) => get(url.GET_CUSTOMERS + query)
const getLicencesAccess = ({ customerId }) => get(url.GET_LICENCES_ACCESS + customerId)
const createCustomer = data => post(url.CREATE_CUSTOMER, data)
const sendInvitation = email => post(url.POST_SEND_INVITATION_EMAIL, email)
const deleteCustomer = id => del(url.DELETE_ADMIN + id)
const updateCustomer = (uid, data) => put(url.UPDATE_CUSTOMER + uid, data)
const updateSubAdmin = (uid, data) => put(url.UPDATE_SUB_ADMIN_CUSTOMER + uid, data)
const createLicenceAccess = data => post(url.POST_CREATE_LICENCE_ACCESS, data)
const getUsers = ({ domain }) => get(url.GET_USERS + '/allByDomain?domain=' + domain)
const deleteLicenceAccess = ({ userId }) => del(url.DELETE_LICENCE_ACCESS + userId)
const getCustomersList = (query, customerId) => get(url.GET_USERS_BY_DOMAIN + query + '?customer=' + customerId)

// ** Settings
const getSettings = () => get(url.GET_SETTINGS)

export {
  getUserAuthorization,
  getUserAppsAccess,
  getOrderHistory,
  getDomainByQuery,
  getCustomers,
  getLicencesAccess,
  createCustomer,
  getSettings,
  deleteCustomer,
  createLicenceAccess,
  getUsers,
  deleteLicenceAccess,
  getCustomersList,
  updateCustomer,
  sendInvitation,
  getClientUserAuthorization,
  updateSubAdmin
}
