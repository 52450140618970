// ** React Imports
import React, { useMemo, useState } from 'react'

// ** APIs
import { getSettings } from 'configs/axios/api_helper'

// ** Hooks
import useFetch from 'hooks/useFetch'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
import useMediaQuery from "@mui/material/useMediaQuery"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"

// ** Styled Grid
const Wrapper = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2rem",
    marginBottom: "2rem"
}))

// ** Styled Grid
const Container = styled(Card)(({ theme }) => ({
    transition: "all 1s",
    "&.MuiPaper-root": {
        borderRadius: "10px !important",
        boxShadow: theme.shadows[0],
        border: `1px solid ${theme.palette.grey[300]}`,
        paddingRight: "2rem",
        paddingLeft: "2rem",
        width: "50%",
        [theme.breakpoints.down('xl')]: {
            width: "70%",
        },
        [theme.breakpoints.down('lg')]: {
            width: "80%",
        },
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
}))

// ** Fields container
const FieldContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "end",
    justifyContent: "space-between",
    marginTop: "1rem",
    marginBottom: "1rem",
    [theme.breakpoints.down('md')]: {
        alignItems: "start",
        flexDirection: "column",
        gap: 10
    },
}))

// ** Field Text Preview
const FieldPreview = styled(Typography)(({ theme }) => ({
    fontSize: "1rem",
    fontFamily: "Roboto",
    fontWeight: 500,
    cursor: "pointer"
}))

// ** Header Title
const Header = styled(Typography)(({ theme }) => ({
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "1.375rem",
    marginLeft: "-1.225rem"
}))


const Profile = () => {

    // ** State
    const [fields, setFields] = useState([])

    // ** Hooks
    const { data, loading, error } = useFetch(getSettings)
    const small = useMediaQuery(theme => theme.breakpoints.down('md'))

    // ** Memo
    const settings = useMemo(() => { return data?.length > 0 ? data[0] : [] }, [data])

    // ** Functions
    const onCancel = () => setFields([])

    const showFieldHandler = (field) => setFields([...fields, field])


    return (
        <Grid container >
            <Grid item xs={12} sx={{ textAlign: "center", mb: "3rem" }}>
                <Typography variant="h4" sx={{ fontFamily: "Roboto", }}>
                    Personal info
                </Typography>
                <Typography variant="body2" sx={{ fontFamily: "Roboto", fontSize: "1rem" }}>
                    Info about you and your account settings
                </Typography>
            </Grid>
            <Wrapper item container xs={12}>
                <Container>
                    <CardHeader
                        title={
                            <Header>
                                Main Settings
                            </Header>
                        }
                    />
                    <Grid item xs={12}>
                        <FieldContainer>
                            <Typography variant="body2" sx={{ fontSize: "0.785rem", fontWeight: 600 }}>
                                Domain super admin
                            </Typography>
                            {fields.some(el => el === "plDomainSuperAdmin") ?
                                <TextField
                                    name="plDomainSuperAdmin"
                                    variant="standard"
                                    sx={{ mb: "-0.425rem", minWidth: 350 }}
                                    inputProps={{ style: { fontSize: 14 } }} // font size of input text
                                    placeholder={settings?.[0]?.plDomainSuperAdmin || (settings?.[1]?.plDomainSuperAdmin ?? "")}
                                />
                                :
                                <FieldPreview onClick={() => showFieldHandler("plDomainSuperAdmin")}>
                                    {(settings?.[0]?.plDomainSuperAdmin ?? settings?.[1]?.plDomainSuperAdmin) || "-----"}
                                </FieldPreview>
                            }
                        </FieldContainer>
                        <Divider sx={{ borderBottom: theme => `1px solid ${theme.palette.grey[300]}` }} />
                        <FieldContainer>
                            <Typography variant="body2" sx={{ fontSize: "0.785rem", fontWeight: 600 }}>
                                Access group
                            </Typography>
                            {fields.some(el => el === "plAccessGroup") ?
                                <TextField
                                    name="plAccessGroup"
                                    variant="standard"
                                    sx={{ mb: "-0.425rem", minWidth: 350 }}
                                    inputProps={{ style: { fontSize: 14 } }} // font size of input text
                                    placeholder={settings?.[0]?.plAccessGroup || (settings?.[1]?.plAccessGroup ?? "")}
                                />
                                :
                                <FieldPreview onClick={() => showFieldHandler("plAccessGroup")}>
                                    {settings?.[0]?.plAccessGroup || (settings?.[1]?.plAccessGroup ?? "-----")}
                                </FieldPreview>
                            }
                        </FieldContainer>
                        <Divider sx={{ borderBottom: theme => `1px solid ${theme.palette.grey[300]}` }} />
                        <FieldContainer>
                            <Typography variant="body2" sx={{ fontSize: "0.785rem", fontWeight: 600 }}>
                                Reseller API delegation user
                            </Typography>
                            {fields.some(el => el === "plResellerDelegationUser") ?
                                <TextField
                                    name="plResellerDelegationUser"
                                    variant="standard"
                                    sx={{ mb: "-0.425rem", minWidth: 350 }}
                                    inputProps={{ style: { fontSize: 14 } }} // font size of input text
                                    placeholder={settings?.[0]?.plResellerDelegationUser || (settings?.[1]?.plResellerDelegationUser ?? "")}
                                />
                                :
                                <FieldPreview onClick={() => showFieldHandler("plResellerDelegationUser")}>
                                    {settings?.[0]?.plResellerDelegationUser || (settings?.[1]?.plResellerDelegationUser ?? "-----")}
                                </FieldPreview>
                            }
                        </FieldContainer>
                    </Grid>
                </Container>
            </Wrapper>
            <Wrapper item container xs={12}>
                <Container>
                    <CardHeader
                        title={
                            <Header>
                                SMTP Settings
                            </Header>
                        }
                    />
                    <Grid item xs={12}>
                        <FieldContainer >
                            <Typography variant="body2" sx={{ fontSize: "0.785rem", fontWeight: 600 }}>
                                SMTP Host
                            </Typography>
                            {fields.some(el => el === "plHost") ?
                                <TextField
                                    name="plHost"
                                    variant="standard"
                                    sx={{ mb: "-0.425rem", minWidth: 350 }}
                                    inputProps={{ style: { fontSize: 14 } }} // font size of input text
                                    placeholder={settings?.[0]?.plHost || (settings?.[1]?.plHost ?? "")}
                                />
                                :
                                <FieldPreview onClick={() => showFieldHandler("plHost")}>
                                    {settings?.[0]?.plHost || (settings?.[1]?.plHost ?? "-----")}
                                </FieldPreview>
                            }
                        </FieldContainer>
                        <Divider sx={{ borderBottom: theme => `1px solid ${theme.palette.grey[300]}` }} />
                        <FieldContainer >
                            <Typography variant="body2" sx={{ fontSize: "0.785rem", fontWeight: 600 }}>
                                SMTP Port
                            </Typography>
                            {fields.some(el => el === "plPort") ?
                                <TextField
                                    name="plPort"
                                    variant="standard"
                                    sx={{ mb: "-0.425rem", minWidth: 350 }}
                                    inputProps={{ style: { fontSize: 14 } }} // font size of input text
                                    placeholder={settings?.[0]?.plPort || (settings?.[1]?.plPort ?? "")}
                                />
                                :
                                <FieldPreview onClick={() => showFieldHandler("plPort")}>
                                    {settings?.[0]?.plPort || (settings?.[1]?.plPort ?? "-----")}
                                </FieldPreview>
                            }
                        </FieldContainer>
                        <Divider sx={{ borderBottom: theme => `1px solid ${theme.palette.grey[300]}` }} />
                        <FieldContainer >
                            <Typography variant="body2" sx={{ fontSize: "0.785rem", fontWeight: 600 }}>
                                Authentification
                            </Typography>
                            {fields.some(el => el === "plLogin") ?
                                <TextField
                                    name="plLogin"
                                    variant="standard"
                                    sx={{ mb: "-0.425rem", minWidth: 350 }}
                                    inputProps={{ style: { fontSize: 14 } }} // font size of input text
                                    placeholder={settings?.[0]?.plLogin || (settings?.[1]?.plLogin ?? "")}
                                />
                                :
                                <FieldPreview onClick={() => showFieldHandler("plLogin")}>
                                    {settings?.[0]?.plLogin || (settings?.[1]?.plLogin ?? "-----")}
                                </FieldPreview>
                            }
                        </FieldContainer>
                        <Divider sx={{ borderBottom: theme => `1px solid ${theme.palette.grey[300]}` }} />
                        <FieldContainer >
                            <Typography variant="body2" sx={{ fontSize: "0.785rem", fontWeight: 600 }}>
                                Email from
                            </Typography>
                            {fields.some(el => el === "plEmailFrom") ?
                                <TextField
                                    name="plEmailFrom"
                                    variant="standard"
                                    sx={{ mb: "-0.425rem", minWidth: 350 }}
                                    inputProps={{ style: { fontSize: 14 } }} // font size of input text
                                    placeholder={settings?.[0]?.plEmailFrom || (settings?.[1]?.plEmailFrom ?? "")}
                                />
                                :
                                <FieldPreview onClick={() => showFieldHandler("plEmailFrom")}>
                                    {settings?.[0]?.plEmailFrom || (settings?.[1]?.plEmailFrom ?? "-----")}
                                </FieldPreview>
                            }
                        </FieldContainer>
                    </Grid>
                </Container>
            </Wrapper>
            <Wrapper item xs={12} >
                <Container sx={{ "&.MuiPaper-root": { border: 0, px: 0 }, display: "flex", justifyContent: "end", gap: 5 }}>
                    <Button disabled={loading} onClick={onCancel} size="small" variant="contained" color="inherit">
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        endIcon={(loading ? <CircularProgress sx={{ color: 'common.white', mt: 0 }} size="0.825rem" /> : null)}
                        variant="contained"
                        size="small"
                        sx={{ fontWeight: 600, primary: "common.blue" }}
                    >
                        Send Test
                    </Button>
                </Container>
            </Wrapper>
        </Grid >
    )
}


export default Profile