// ** React Imports
import React from 'react'
import { Navigate, redirect } from 'react-router-dom'

// ** Authentifications
import Login from 'pages/auth'

// Licences
import Orders from 'pages/orders/containers/OrderHistory'

// Users
import CustomersTable from 'pages/customers/containers/Customers'

// Profile
import Profile from 'pages/profile/container/Profile'

const authProtectedRoutes = [
  { path: '/orders', component: Orders },
  { path: '/customers', component: CustomersTable },
  { path: '/profile', component: Profile },
  { path: '/', exact: true, component: () => <Navigate to='/customers' /> },
  { path: '*', component: () => <Navigate to='/customers' /> }
]

const publicRoutes = [{ path: '/login', component: Login }]

export { authProtectedRoutes, publicRoutes }
