// ** React Imports
import React, { Fragment, useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'

// ** Third Party Liraries
import { useDebouncedCallback } from 'use-debounce'

// ** Icons Imports
import UserIcon from 'mdi-material-ui/AccountOutline'
import DomainIcon from 'mdi-material-ui/Domain'

// ** Styled Box
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  marginTop: 5
}))

const SearchField = props => {
  // ** Props
  const { setQuery, customers } = props

  // ** Constants
  const uniqueCustomers = new Set()
  const options = customers?.filter(obj => {
    if (!uniqueCustomers.has(obj?.domain)) {
      uniqueCustomers.add(obj?.domain)
      return true
    }
    return false
  })
  // ** State
  const [type, setType] = useState('domain')

  // ** Functions
  const changeType = type => setType(type)

  const fieldSearchHandler = value => {
    if (!value) setQuery('list')
    else setQuery(`search?${type}=${type === 'domain' ? value?.domain : value?.admin}`)
  }

  // const debounced = useDebouncedCallback(
  //     async (event) => {
  //         const { value } = event.target;
  //         if (!value)
  //             setQuery("list")
  //         else
  //             setQuery(`search?${type}=${value}`)
  //     },
  //     500,
  //     // The maximum time func is allowed to be delayed before it's invoked:
  //     { maxWait: 3000 }
  // );

  return (
    <Container>
      <Autocomplete
        id='autocomplete-domains'
        popupIcon={''}
        autoHighlight
        options={options}
        getOptionLabel={option => (type === 'domain' ? option?.domain : option?.admin)}
        renderInput={params => (
          <TextField
            {...params}
            variant='standard'
            label={type === 'domain' ? 'Search by domain' : 'Search by users'}
            placeholder="Search for user's email"
            InputLabelProps={{ style: { fontSize: '0.925rem', marginBottom: '0.325rem' } }}
          />
        )}
        onChange={(event, value) => fieldSearchHandler(value)}
        sx={{ width: '300px' }}
      />
      <Tooltip title='Search by domain'>
        <IconButton
          size='large'
          edge='start'
          aria-label='menu'
          sx={{
            color: 'common.black',
            mt: '1.625rem',
            ml: '0.5rem',
            ...(type === 'domain' ? { color: 'primary.main' } : { color: 'text.secondary' })
          }}
          onClick={() => changeType('domain')}
        >
          <DomainIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Search by admin'>
        <IconButton
          size='large'
          edge='start'
          aria-label='menu'
          sx={{
            color: 'common.black',
            mt: '1.625rem',
            ...(type === 'admin' ? { color: 'primary.main' } : { color: 'text.secondary' })
          }}
          onClick={() => changeType('admin')}
        >
          <UserIcon />
        </IconButton>
      </Tooltip>
    </Container>
  )
}

export default SearchField
