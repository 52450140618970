// ** React Imports
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// ** Axios 
import axios from 'axios'

// ** Google Login Import 
import { useGoogleLogin } from '@react-oauth/google'

// ** Hooks
import { useAuth } from 'hooks/useAuth'


// ** MUI Components
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled, useTheme } from '@mui/material/styles'
import MuiCard from '@mui/material/Card'
import Alert from '@mui/material/Alert'

// ** Images
import GoogleImage from "assets/images/logo/google-logo.svg"
import PremierCloud from "assets/images/premier_cloud.png"

// ** Custome Component
import FallbackSpinner from 'core/components/spinner'


// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  zIndex: 1,
  display: 'block',
  boxShadow: "none",
  border: `solid 1px ${theme.palette.grey[300]}`,
  height: 'auto',
  padding: "2.5rem"
}))

const GoogleButton = styled(Button)(({ theme }) => ({
  fontSize: "0.92rem",
  textTransform: "none",
  //width: "fit-content",
  paddingLeft: "1.5rem",
  paddingRight: "1.5rem",
  fontWeight: 600,
  color: theme.palette.common.black,
  borderRadius: "0.3em",
  height: 48,
  width: 240,
  marginBottom: "1.725rem",
  textAlign: 'center',
  border: `1px solid ${theme.palette.grey[300]}`,
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
    border: `1px solid ${theme.palette.grey[300]}`,
  }
}))


const LoginV1 = () => {

  // ** Hooks
  const auth = useAuth()


  const googleLogin = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      // fetching userinfo can be done on the client or the server from google api 
      const userInfo = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: { Authorization: `Bearer ${access_token}` },
      }).then(res => res.json());
      auth.login({ ...userInfo, access_token }, ({ message, code }) => {

      })
    },
  });


  return (
    <Box className='content-center' sx={{ minHeight: "100vh" }}>
      <Card >
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <img width={180} height={85} alt="google_cloud" src={PremierCloud} />
            <Typography variant='h6' sx={{ ml: 2, mt: "3rem", lineHeight: 1, fontWeight: 700, fontSize: '1.5rem !important' }}>
              Sign In
            </Typography>
          </Box>
          <Box sx={{ mb: 6, textAlign: 'center', mt: "0.5rem" }}>
            <Typography variant='body1'>Sign in with your Google account</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: "center" }}>
            <GoogleButton
              onClick={googleLogin}
              variant="outlined"
              startIcon={<img width={20} height={20} alt="google-logo" src={GoogleImage} />}
            >
              Sign in with Google
            </GoogleButton>
          </Box>
          {auth.error && <Alert severity='error'>You are not authorized to use this application !</Alert>}
          {auth.loading && <FallbackSpinner />}
        </CardContent>
      </Card >
    </Box >
  )
}

export default LoginV1
