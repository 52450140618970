// ** React Imports
import React from 'react'
import PropTypes from 'prop-types';
import { Routes, BrowserRouter as Router, Route, Navigate } from "react-router-dom";

// ** Google Auth Wrapper
import { GoogleOAuthProvider } from '@react-oauth/google'

// ** Settings Context
import { SettingsConsumer, SettingsProvider } from 'core/context/SettingsContext';

// ** Layout Import
import UserLayout from 'layouts/UserLayout';

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import ThemeComponent from 'core/theme/ThemeComponent';
import BlankLayout from 'core/layouts/BlankLayout';
import { AuthProvider } from 'context/AuthContext';
import AuthGuard from 'routes/route';



// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'


function App(props) {

  const { Component, pageProps } = props



  const getLayout = ({ component: Component }) => <UserLayout><Component /></UserLayout>
  const authLayout = ({ component: Component }) => <BlankLayout><Component /></BlankLayout>


  return (
    <GoogleOAuthProvider clientId="425168470577-4ele56hqgbbh0m1vm9hckh3biageqqep.apps.googleusercontent.com">
      <SettingsProvider >
        <SettingsConsumer>
          {({ settings }) => {
            return (
              <ThemeComponent settings={settings}>
                <Router>
                  <AuthProvider>
                    <Routes>
                      {publicRoutes.map((route, idx) => (
                        <Route
                          key={idx}
                          path={route.path}
                          element={
                            <AuthGuard protectedRoute={false}>
                              {authLayout(route)}
                            </AuthGuard>
                          }
                        />
                      ))}
                      {authProtectedRoutes.map((route, idx) => (
                        <Route
                          key={idx}
                          path={route.path}
                          element={
                            <AuthGuard redirectTo={"/login"} protectedRoute={true}>
                              {getLayout(route)}
                            </AuthGuard>
                          }
                        />
                      ))}
                    </Routes>
                  </AuthProvider>
                </Router>
              </ThemeComponent>
            )
          }}
        </SettingsConsumer>
      </SettingsProvider >
    </GoogleOAuthProvider>
  );
}

App.propTypes = {
  layout: PropTypes.any
};

export default App;
