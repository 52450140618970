import { useEffect, useState, useMemo } from "react"


export default function useFetch(apiCall, query = {}) {

    // ** States
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [params, setParams] = useState({})

    // ** Prevent infinite loop in useEffect when the query object changes using provide key "property"
    const checkParams = useMemo(() => { if (params?.query !== query?.query) setParams(query) }, [query])

    // ** Functions
    const fetchData = async () => {
        try {
            setLoading(true)
            const response = await apiCall(params)
            setData(response.data)
        } catch (err) {
            setError(err.response)
        } finally {
            setLoading(false)
        }
    }

    const refetch = () => fetchData()

    // ** Execute api call when component mount
    useEffect(() => { fetchData() }, [apiCall, params])

    // ** Return states
    return { data, error, loading, refetch }

}