// ** React Imports
import React, { useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { LocalizationProvider, StaticDateRangePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from '@mui/material/TextField'


const DatePickerDialog = (props) => {

    // ** Props
    const { open, setOpen } = props

    // ** State 
    const [value, setValue] = useState([null, null])

    // ** Functions
    const closeHandler = () => setOpen(false)

    const selectDateHandler = () => {
        console.log("aaa", value)
    }
    return (
        <Dialog
            sx={{ "& .MuiDialog-paper": { width: "670px" } }}
            fullWidth={true}
            maxWidth="md"
            open={open}
        >
            <DialogTitle>Pick Date</DialogTitle>
            <DialogContent dividers sx={{ textAlign: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticDateRangePicker
                        displayStaticWrapperAs="desktop"
                        value={value}
                        onChange={(newValue) => setValue(newValue)}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <TextField {...startProps} />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <TextField {...endProps} />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={closeHandler}>
                    Cancel
                </Button>
                <Button onClick={selectDateHandler}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DatePickerDialog