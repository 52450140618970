// ** React Imports
import React, { Fragment, useState } from 'react'

// ** API CALLS
import { deleteCustomer } from 'configs/axios/api_helper'

// ** Hooks
import { useAuth } from 'hooks/useAuth'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'

// ** Icon Imports
import DeleteIcon from 'mdi-material-ui/TrashCanOutline'
import Alert from '../alert'

const DeleteCustomer = props => {
  // ** Props
  const { user, refetch, setQuery } = props

  // ** Hooks
  const auth = useAuth()

  // ** State
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ success: false, error: false, value: '' })

  // ** Functions
  const handleClickOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const handleCloseMessage = () => {
    setMessage({ success: false, error: false, value: '' })
  }

  const deleteDeleteHandler = async e => {
    e.stopPropagation()
    setLoading(true)
    try {
      const response = await deleteCustomer(user.uid)
      setQuery('list')
      setLoading(false)
      setMessage({ success: true, error: false, value: 'Customer was deleted successfully' })
      setOpen(false)
    } catch (error) {
      console.log('errr', error)
      setLoading(false)
      setMessage({ success: false, error: true, value: 'There was an error while deleting the customer' })
    }
  }

  return (
    <Fragment>
      <IconButton size='medium' edge='start' sx={{ color: 'text.secondary' }} onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Delete admin</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to remove admin <strong>{user?.admin}</strong> ?
          </DialogContentText>
        </DialogContent>
        <DialogActions className='dialog-actions-dense' sx={{ mt: '1rem' }}>
          <Button disabled={loading} onClick={handleClose} size='small' variant='text' color='inherit'>
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={deleteDeleteHandler}
            endIcon={loading ? <CircularProgress sx={{ color: 'common.red', mt: 0 }} size='0.825rem' /> : null}
            variant='text'
            size='small'
            sx={{ fontWeight: 600 }}
            color='error'
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Alert message={message} handleCloseMessage={handleCloseMessage} />
    </Fragment>
  )
}

export default DeleteCustomer
