// ** React Imports
import React, { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** Third party libraries
import { useCookies } from 'react-cookie'
import axios from 'axios'

// ** Api Calls
import { getUserAuthorization } from 'configs/axios/api_helper'

// ** MUI Imports
import Box from '@mui/material/Box'

// ** SPINNER
import GoogleSpinner from 'core/components/google-spinner'

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  error: false,
  isInitialized: false,
  login: () => null,
  logout: () => null,
  setIsInitialized: () => Boolean,
  register: () => Promise.resolve()
}
const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [loading, setLoading] = useState(defaultProvider.loading)
  const [error, setError] = useState(defaultProvider.error)
  const [isInitialized, setIsInitialized] = useState(defaultProvider.isInitialized)

  // ** Hooks
  const navigate = useNavigate()

  // ** Cookies
  const [cookies, setCookie, removeCookie] = useCookies(['gToken', 'userData', 'externalToken', 'token'])

  // ** Get Token from external app redirection
  const search = window.location.search
  const params = new URLSearchParams(search)
  const externalToken = params.get('token')

  useEffect(() => {
    const initAuth = async () => {
      setIsInitialized(true)
      setLoading(true)
      const storedGoogleToken = cookies.gToken
      try {
        const googleUser = await axios
          .get(`https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${storedGoogleToken || externalToken}`)
          .then(res => res.data)
        // const appsAccess = await axios
        //   .post(`https://backend-yyxlncaila-uc.a.run.app/api/auth/admins/appsaccess`, { email: googleUser.email })
        //   .then(res => res.data)
        const storedUser = cookies.userData
        setUser({
          id: storedUser.id,
          email: storedUser.email,
          name: storedUser.name,
          picture: storedUser.picture,
          access_apps: []
        })
        setLoading(false)
        if (window.location.pathname == '/login') navigate('/')
        else navigate(window.location.pathname)
      } catch (error) {
        removeCookie('gToken')
        removeCookie('token')
        removeCookie('userData')
        setUser(null)
        setLoading(false)
        navigate('/login')
      }
    }
    initAuth()
  }, [])

  const handleLogin = async (data, errorCallback) => {
    setLoading(true)
    setError(false)
    try {
      // call apis for collecting variant user data & access
      const token = await getUserAuthorization(data.email).then(res => res.data?.token)
      // const appsAccess = await axios.post(`https://backend-yyxlncaila-uc.a.run.app/api/auth/admins/appsaccess`, { email: data.email }).then(res => res.data)
      // Initialise userData
      const userData = { id: data.sub, email: data.email, name: data.name, picture: data.picture, access_apps: [] }
      // Set Cookies
      setCookie('gToken', data.access_token)
      setCookie('token', token)
      setCookie('userData', userData)
      // Set auth data
      setUser(userData)
      setLoading(false)
      navigate('/')
    } catch (error) {
      setLoading(false)
      errorCallback({ message: 'not authorized', code: 401 })
      setError(true)
    }
  }

  const handleLogout = () => {
    setUser(null)
    setIsInitialized(false)
    removeCookie('userData')
    removeCookie('gToken')
    removeCookie('token')
    navigate('/login')
  }

  const values = {
    user,
    loading,
    error,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    logout: handleLogout
  }
  if (loading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', mt: '20%' }}>
        <GoogleSpinner />
      </Box>
    )
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
