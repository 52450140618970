// ** React Imports
import React, { Fragment, useState } from 'react'

// ** APIs
import { getDomainByQuery } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

// ** Third Party Liraries
import { useDebouncedCallback } from 'use-debounce'
import moment from 'moment'

// ** Icons Imports
import UserIcon from 'mdi-material-ui/AccountOutline'
import DomainIcon from 'mdi-material-ui/Domain'
import FilterIcon from 'mdi-material-ui/FilterVariant'
import DatePickerDialog from '../dialog/DatePickerDialog'

// ** Styled Box
const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 5
}))

const SearchField = props => {
  // ** Props
  const { setQuery, orders } = props

  // ** Constants
  const uniqueOrders = new Set()
  const options = orders?.filter(obj => {
    if (!uniqueOrders.has(obj?.customerDomain)) {
      uniqueOrders.add(obj?.customerDomain)
      return true
    }
    return false
  })

  // ** State
  const [anchorEl, setAnchorEl] = useState(null)
  const [filter, setFilter] = useState(null)
  const [type, setType] = useState('domain')
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false)

  // ** Functions

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onChange = item => {
    console.log('item', item)
  }

  const changeType = type => setType(type)

  const fieldSearchHandler = value => {
    if (!value) setQuery('list')
    else setQuery(`search?${type}=${type === 'domain' ? value?.customerDomain : value?.customerDetail?.customerName}`)
  }

  const debounced = useDebouncedCallback(
    async event => {
      const { value } = event.target
      if (!value) setQuery('list')
      else setQuery(`search?${type}=${value}`)
    },
    500,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 3000 }
  )

  const onChangeFilter = date => {
    setFilter(date)
    setAnchorEl(null)
    let timestamp = moment().startOf('day').format('x')
    if (date === 'Today') {
      timestamp = moment().startOf('day').format('x')
    } else if (date === 'Yesterday') {
      timestamp = moment(new Date()).subtract(1, 'days').startOf('day').format('x')
    } else if (date === 'Last 7 days') {
      timestamp = moment(new Date()).subtract(7, 'days').startOf('day').format('x')
    } else if (date === 'Last 30 days') {
      timestamp = moment(new Date()).subtract(30, 'days').startOf('day').format('x')
    } else if (date === 'Last 90 days') {
      timestamp = moment(new Date()).subtract(90, 'days').startOf('day').format('x')
    }
    setQuery('filter?timestamp=' + timestamp)
  }

  const resetFilterHandler = () => {
    setFilter(null)
    setQuery('list')
  }

  return (
    <Container>
      <Autocomplete
        id='autocomplete'
        popupIcon={''}
        autoHighlight
        options={options}
        getOptionLabel={option => (type === 'domain' ? option?.customerDomain : option.customerDetail?.customerName)}
        renderInput={params => (
          <TextField
            {...params}
            variant='standard'
            label={type === 'domain' ? 'Search by domain' : 'Search by users'}
            placeholder="Search for user's email"
            InputLabelProps={{ style: { fontSize: '0.925rem', marginBottom: '0.325rem' } }}
          />
        )}
        onChange={(event, value) => fieldSearchHandler(value)}
        sx={{ width: '300px' }}
      />

      <Tooltip title='Search by domain'>
        <IconButton
          size='large'
          edge='start'
          aria-label='menu'
          sx={{
            color: 'common.black',
            mt: '1.625rem',
            ml: '0.5rem',
            ...(type === 'domain' ? { color: 'primary.main' } : { color: 'text.secondary' })
          }}
          onClick={() => changeType('domain')}
        >
          <DomainIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Search by user'>
        <IconButton
          size='large'
          edge='start'
          aria-label='menu'
          sx={{
            color: 'common.black',
            mt: '1.625rem',
            ...(type === 'user' ? { color: 'primary.main' } : { color: 'text.secondary' })
          }}
          onClick={() => changeType('user')}
        >
          <UserIcon />
        </IconButton>
      </Tooltip>
      <Button
        variant='text'
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        sx={{ mt: '1.625rem', color: 'black !important', whiteSpace: 'nowrap' }}
        startIcon={<FilterIcon size='0.825rem' />}
      >
        Add date filter
      </Button>
      <Menu keepMounted id='simple-menu' anchorEl={anchorEl} onClose={handleClose} open={Boolean(anchorEl)}>
        <MenuItem onClick={() => onChangeFilter('Today')}>Today</MenuItem>
        <MenuItem onClick={() => onChangeFilter('Yesterday')}>Yesterday</MenuItem>
        <MenuItem onClick={() => onChangeFilter('Last 7 days')}>Last 7 days</MenuItem>
        <MenuItem onClick={() => onChangeFilter('Last 30 days')}>Last 30 days</MenuItem>
        <MenuItem onClick={() => onChangeFilter('Last 90 days')}>Last 90 days</MenuItem>
        {/* <MenuItem onClick={() => setOpen(true)}>Custom date range</MenuItem> */}
      </Menu>
      {filter ? (
        <Chip label={filter} variant='outlined' onDelete={resetFilterHandler} size='small' sx={{ mt: '1.645rem' }} />
      ) : null}
      <DatePickerDialog open={open} setOpen={setOpen} />
    </Container>
  )
}

export default SearchField
