// ** Icon imports
import LicencesIcon from 'mdi-material-ui/Google'
import UsersIcons from 'mdi-material-ui/AccountGroupOutline'

const navigation = () => {
  return [
    {
      title: 'Customers',
      icon: UsersIcons,
      path: '/customers'
    },
    {
      title: 'Orders',
      icon: LicencesIcon,
      path: '/orders'
    }
  ]
}

export default navigation
