// ** React Import
import React from 'react'

// ** Layout Components
import VerticalLayout from './VerticalLayout'

const Layout = props => {
    // ** Props
    const { children } = props

    return <VerticalLayout {...props}>{children}</VerticalLayout>
}

export default Layout
