// ** Authentification
export const GET_USER_AUTHORIZATION = '/auth/direct'
export const GET_USER_APPS_ACCESS = '/auth/admins/appsaccess'

// ** Orders
export const GET_ORDERS = '/customerOrderhistory/'

// ** Domain
export const GET_SEARCH_DOMAINS = '/customerOrderhistory/search?'

// ** Customers
export const GET_CUSTOMERS = '/licenseCustomers/'
export const CREATE_CUSTOMER = '/licenseCustomers/create'
export const GET_LICENCES_ACCESS = '/licenceAccess/getLicenceAccess/'
export const DELETE_ADMIN = '/licenseCustomers/delete/'
export const POST_CREATE_LICENCE_ACCESS = '/licenceAccess/create/'
export const GET_USERS = '/users'
export const DELETE_LICENCE_ACCESS = '/licenceAccess/delete/'
export const GET_LICENCE_ACCESS = '/licenseAccess/list'
export const UPDATE_CUSTOMER = '/licenseCustomers/update/'
export const UPDATE_SUB_ADMIN_CUSTOMER = '/licenceAccess/update/'
export const POST_SEND_INVITATION_EMAIL = '/email/invitationEmail'
export const GET_USERS_BY_DOMAIN = '/users/allByCustomerByQuery/'

// ** Settings
export const GET_SETTINGS = '/settings/list'
