// ** React Imports
import React from 'react'

// ** MUI Imports
import Snackbar from '@mui/material/Snackbar'
import Box from '@mui/material/Box'
import Grow from '@mui/material/Grow'


const Alert = (props) => {

    // ** Props
    const { message, handleCloseMessage } = props


    return (
        <Snackbar
            sx={{ mt: "3rem" }}
            open={message.success || message.error}
            onClose={handleCloseMessage}
            autoHideDuration={1500}
            key={"top" + "right"}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            TransitionComponent={Grow}
            message={
                <Box sx={{ textAlign: 'center' }}>
                    {message.value}
                </Box>
            }
        />
    )
}

export default Alert