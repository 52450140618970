// ** React Imports
import React, { useState, Fragment, useMemo } from 'react'

// ** APIs
import { getOrderHistory } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'

// ** moment
import moment from 'moment'

// ** Third Party Libraries
import { CSVLink } from 'react-csv'

// ** Component Imports
import CustomTable from '../components/tables/CustomTable'
import CustomAvatar from 'core/components/mui/avatar'
import SearchField from '../components/inputs/SearchField'

// ** Hooks
import useFetch from 'hooks/useFetch'
import { useAuth } from 'hooks/useAuth'

// ** Icon Imports
import DonwloadIcon from 'mdi-material-ui/TrayArrowDown'

// ** Google Products Data
import { products } from '@fake-db/products'

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: 1,
  color: theme.palette.grey[300]
}))

const Row = props => {
  // ** Props
  const { row, key } = props

  // **Fucntions
  const productIcon = name => products.find(el => el.displayName === name)?.defaultLogo?.content

  return (
    <Fragment key={key}>
      <TableRow sx={{ '& > *': {}, cursor: 'pointer' }}>
        <TableCell>{row.orderID}</TableCell>
        <TableCell>{row.customerDomain}</TableCell>
        <TableCell>
          {row.timestamp ? moment(new Date(row.timestamp)).format('MMM DD, YYYY, HH:mm:ss') : '----'}
        </TableCell>
        <TableCell component='th' scope='row'>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <CustomAvatar
              src={productIcon(row.product) || ''}
              sx={{ mr: 3, width: '1.875rem', height: '1.875rem', borderRadius: 0 }}
            />
            <Typography noWrap variant='body2' sx={{ color: 'common.blue' }}>
              {row.product}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>{row.customerDetail?.customerName}</TableCell>
        <TableCell>{row.noOfSeats}</TableCell>
      </TableRow>
    </Fragment>
  )
}

const OrderHistoryTable = () => {
  // ** State
  const [collapsed, setCollapsed] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [query, setQuery] = useState('list')

  // ** Hooks
  const auth = useAuth()
  const small = useMediaQuery(theme => theme.breakpoints.down('md'))
  const { data, loading, error } = useFetch(getOrderHistory, { query })

  // ** Memo
  const orders = useMemo(() => {
    return data?.length > 0 ? data[0] : []
  }, [data])

  // ** Constants
  const csvHeaders = [
    { label: 'Order Id', key: 'orderId' },
    { label: 'Domain', key: 'domain' },
    { label: 'Timestamp', key: 'timestamp' },
    { label: 'Product', key: 'product' },
    { label: 'Ordered By', key: 'orderBy' },
    { label: 'Seats', key: 'seats' }
  ]
  const csvData = orders.map(item => ({
    orderId: item.orderID,
    domain: item.customerDomain,
    timestamp: moment(new Date(item.timestamp)).format('MMM DD, YYYY, HH:mm:ss'),
    product: item.product,
    orderBy: item.customerDetail?.customerName,
    seats: item.noOfSeats
  }))

  // ** Functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Card
      sx={{
        '&.MuiPaper-root': {
          borderRadius: '0px !important',
          boxShadow: 0,
          border: theme => `1px solid ${theme.palette.grey[300]}`
        },
        '& .customer-column-header': {
          backgroundColor: 'common.white',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600
          }
        }
      }}
    >
      <CardHeader
        sx={{ ...(small ? { overflowX: 'scroll' } : {}) }}
        title={
          <Box sx={{ display: 'flex', gap: 20, mt: '-1rem', mb: '-1rem' }}>
            <Typography variant='h5' sx={{ mt: '2rem' }}>
              Orders
            </Typography>
            <SearchField setQuery={setQuery} orders={orders} />
          </Box>
        }
        action={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              cursor: 'pointer',
              '&:hover': { backgroundColor: theme => theme.palette.grey[200] },
              pt: '1.325rem',
              px: '0.825rem',
              borderRadius: 0.3
            }}
          >
            <DonwloadIcon sx={{ fontSize: '1.225rem' }} />
            <CSVLink data={csvData} headers={csvHeaders} filename='orders.csv' style={{ textDecoration: 'none' }}>
              <Typography variant='body1' sx={{ fontSize: '0.925rem', fontWieght: '600' }}>
                EXPORT ORDERS
              </Typography>
            </CSVLink>
          </Box>
        }
      />
      <StyledDivider />
      <CustomTable
        data={orders ?? []}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        columns={['ORDER ID', 'DOMAIN', 'TIMESTAMPS', 'PRODUCT', 'ORDERD BY', 'SEATS NUMBER']}
        page={page}
        rowsPerPage={rowsPerPage}
        loading={loading}
      >
        {orders
          ?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          ?.map((row, index) => (
            <Row key={index} row={row} />
          ))}
      </CustomTable>
    </Card>
  )
}

export default OrderHistoryTable
