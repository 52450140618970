// ** React Imports
import React, { useEffect } from "react"
import { Navigate, useLocation } from "react-router-dom"

// ** Auth Context
import { useAuth } from 'hooks/useAuth'


const AuthGuard = ({ children, redirectTo, protectedRoute }) => {

  // ** Hooks
  const auth = useAuth()
  const { pathname } = useLocation()


  return (
    (protectedRoute && !auth.loading && auth.user === null) ?
      <Navigate to={redirectTo} />
      :
      children

  )
}


export default AuthGuard;
