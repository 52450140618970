// ** React Imports
import React, { useState, Fragment, useMemo } from 'react'

// ** APIs
import {
  getCustomers,
  getLicencesAccess,
  getClientUserAuthorization,
  getUsers,
  updateCustomer,
  updateSubAdmin
} from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'
import Collapse from '@mui/material/Collapse'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import CircularProgress from '@mui/material/CircularProgress'
import Switch from '@mui/material/Switch'

// ** moment
import moment from 'moment'
import Cookies from 'js-cookie'

// ** Component Imports
import CustomTable from '../components/tables/CustomTable'
import SearchField from '../components/inputs/SearchField'

// ** Hooks
import useFetch from 'hooks/useFetch'

// ** Icon Imports
import ChevronDown from 'mdi-material-ui/ChevronDown'
import ChevronUp from 'mdi-material-ui/ChevronUp'
import ViewIcon from 'mdi-material-ui/EyeCircleOutline'

// ** Custom Components
import AddCustomerDialog from '../components/dialog/AddCustomerDialog'
import Alert from '../components/alert'
import DeleteCustomer from '../components/dialog/DeleteCustomer'
import AddAdmin from '../components/dialog/AddAdmin'
import DeleteAdminAccess from '../components/dialog/DeleteAdminAccess'

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: 1,
  color: theme.palette.grey[300]
}))

const SubRow = props => {
  // ** Props
  const { user, fetchCustomerAdmins, setMessage } = props

  // ** State
  const [checked, setChecked] = useState(user?.showAutoTopUp || false)

  // ** Functions
  const activateAutoToUpSubAdmins = async event => {
    const body = {
      ...user,
      showAutoTopUp: event.target.checked
    }
    setChecked(event.target.checked)
    try {
      const response = await updateSubAdmin(user?.uid, body)
      if (event.target.checked) setMessage({ success: true, error: false, value: 'Auto topup was setted successfully' })
      else setMessage({ success: true, error: false, value: 'Auto topup was removed successfully' })
    } catch (error) {
      console.log('error', error)
      setMessage({ success: false, error: true, value: 'There was an error while editing auto topup' })
      setChecked(false)
    }
  }

  return (
    <TableRow key={user.customerId}>
      <TableCell component='th' scope='row'>
        {user.userName}
      </TableCell>
      <TableCell>{user.userEmail}</TableCell>
      <TableCell>
        <DeleteAdminAccess user={user} fetchCustomerAdmins={fetchCustomerAdmins} />{' '}
        <Switch checked={checked} onChange={activateAutoToUpSubAdmins} />
      </TableCell>
    </TableRow>
  )
}

const Row = props => {
  // ** Props
  const { row, key, refetch, setMessage, setQuery, setLoadingRedirect } = props

  // ** State
  const [open, setOpen] = useState(false)
  const [licenceAccess, setLicenceAccess] = useState({
    data: [],
    success: false,
    loading: false,
    error: false
  })
  const [checked, setChecked] = useState(row?.showAutoTopUp || false)

  // ** Functions
  const fetchCustomerAdmins = async () => {
    try {
      const {
        data: { result }
      } = await getUsers({ domain: row?.domain })
      const response = await getLicencesAccess({ customerId: result?.[0]?.customerId })
      setLicenceAccess(state => ({ ...state, loading: false, data: response.data?.[0], success: true }))
    } catch (error) {
      console.log('error', error)
      setLicenceAccess(state => ({ ...state, loading: false, error: true }))
    }
  }

  const openRowHandler = async domain => {
    setOpen(domain)
    if (domain) {
      if (licenceAccess.data.length < 1 && !licenceAccess.success) {
        setLicenceAccess(state => ({ ...state, loading: true }))
        fetchCustomerAdmins()
      }
    }
  }

  const activateAutoToUp = async event => {
    const body = {
      uid: row?.uid,
      admin: row?.admin,
      showAutoTopUp: event.target.checked,
      domain: row?.domain,
      customerId: row?.customerId,
      createdOn: row?.createdOn
    }
    setChecked(event.target.checked)
    try {
      const response = await updateCustomer(row?.uid, body)
      if (event.target.checked) setMessage({ success: true, error: false, value: 'Auto topup was setted successfully' })
      else setMessage({ success: true, error: false, value: 'Auto topup was removed successfully' })
    } catch (error) {
      console.log('error', error)
      setMessage({ success: false, error: true, value: 'There was an error while editing auto topup' })
      setChecked(false)
    }
  }

  const redirectToLicenceAppHandler = async () => {
    try {
      setLoadingRedirect(true)
      const { data } = await getClientUserAuthorization(row.admin, {
        baseURL: 'https://app.premiercloud.com/api/direct'
      })
      const gToken = Cookies.get('gToken')
      const params = `?token=${data?.token}&gToken=${gToken}&user=${JSON.stringify(row)}`
      window.open(`https://app.premiercloud.com/login${params}`, '_blank', 'noopener,noreferrer')
      setLoadingRedirect(false)
    } catch (error) {
      setLoadingRedirect(false)
      console.log('---errorr---', error)
    }
  }

  return (
    <Fragment key={key}>
      <TableRow sx={{ '& > *': {}, cursor: 'pointer' }}>
        <TableCell>{row?.domain}</TableCell>
        <TableCell>{row?.admin}</TableCell>
        <TableCell>{row?.createdOn ? moment(new Date(row?.createdOn)).format('MMM DD, YYYY') : '----'}</TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <DeleteCustomer refetch={refetch} user={row} setQuery={setQuery} />
            <AddAdmin domain={row?.domain} admin={row?.admin} refetch={fetchCustomerAdmins} />
            {open ? (
              <ChevronUp onClick={() => openRowHandler(null)} />
            ) : (
              <ChevronDown onClick={() => openRowHandler(row?.domain)} />
            )}
            <ViewIcon onClick={redirectToLicenceAppHandler} />
          </Box>
        </TableCell>
        <TableCell>
          <Switch checked={checked} onChange={activateAutoToUp} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} sx={{ py: '0 !important' }}>
          <Collapse in={open == row?.domain ? true : false} timeout='auto' unmountOnExit>
            <Grid container spacing={2}>
              {licenceAccess.data.length > 0 && (
                <Grid item xs={12} sx={{ p: '0.825rem' }}>
                  <Table size='small' aria-label='purchases'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {licenceAccess.data.map((user, index) => (
                        <SubRow
                          key={user?.uid}
                          user={user}
                          fetchCustomerAdmins={fetchCustomerAdmins}
                          setMessage={setMessage}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              )}
              {licenceAccess.loading && (
                <Box sx={{ width: '100%', textAlign: 'center', mt: '0.725rem' }}>
                  <CircularProgress color='inherit' size={30} />
                </Box>
              )}
              {licenceAccess.data.length < 1 && !licenceAccess.loading && (
                <Typography sx={{ fontSize: '1.325rem', fontWeight: 600, m: '0.625rem' }}>No Users</Typography>
              )}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

const CustomersTable = () => {
  // ** State
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [query, setQuery] = useState('list')
  const [message, setMessage] = useState({ success: false, error: false, value: '' })
  const [loadingRedirect, setLoadingRedirect] = useState(false)

  // ** Hooks
  const small = useMediaQuery(theme => theme.breakpoints.down('md'))
  const { data, loading, refetch } = useFetch(getCustomers, { query })

  // ** Memo
  const customers = useMemo(() => {
    return data?.length > 0 ? (query !== 'list' ? [data[0]?.[0]] : data[0]) : []
  }, [data])

  // ** Functions //
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleCloseMessage = () => setMessage({ success: false, error: false, value: '' })

  return (
    <Card
      sx={{
        ...(loadingRedirect ? { opacity: 0.5, pointerEvents: 'none' } : {}),
        '&.MuiPaper-root': {
          borderRadius: '0px !important',
          boxShadow: 0,
          border: theme => `1px solid ${theme.palette.grey[300]}`
        },
        '& .customer-column-header': {
          backgroundColor: 'common.white',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600
          }
        }
      }}
    >
      <CardHeader
        sx={{ ...(small ? { overflowX: 'scroll' } : {}) }}
        title={
          <Box sx={{ display: 'flex', gap: 20, mt: '-2rem', mb: '-1rem' }}>
            <Typography variant='h5' sx={{ mt: '2rem' }}>
              Customers
            </Typography>
            <SearchField setQuery={setQuery} customers={customers} />
          </Box>
        }
        action={<AddCustomerDialog refetch={refetch} setMessage={setMessage} setQuery={setQuery} />}
      />
      <StyledDivider />
      <CustomTable
        data={customers ?? []}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        columns={['DOMAIN', 'ADMIN', 'CREATED ON', 'ACTIONS', 'AUTO TOPUP']}
        page={page}
        rowsPerPage={rowsPerPage}
        loading={loading}
      >
        {customers
          ?.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          ?.map((row, index) => (
            <Row
              key={row.uid}
              row={row}
              refetch={refetch}
              setMessage={setMessage}
              setQuery={setQuery}
              setLoadingRedirect={setLoadingRedirect}
            />
          ))}
      </CustomTable>
      <Alert message={message} handleCloseMessage={handleCloseMessage} />
    </Card>
  )
}

export default CustomersTable
